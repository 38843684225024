<template>
  <BaseLayout>
    <template #header>
      <h1>Pengiriman Labu Darah</h1>

      <section
        id="filter-section"
        class="p-3 rounded"
      >
        <b-row class="align-items-center mb-3">
          <b-col cols="12">
            <b-form-group
              label="Tujuan Pengiriman"
              label-for="input-pengiriman"
            >
              <b-form-select
                id="input-division"
                v-model="formData.division"
                :options="options"
                class="mb-1"
                placeholder="Pilih Divisi Lab"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >
                    Pilih Divisi Yang Dituju
                  </b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="align-items-center mb-3">
          <b-col cols="6">
            <b-form-group
              label="Tanggal Pengiriman"
              label-for="start-date"
            >
              <b-form-input
                id="start-date"
                v-model="formData.date"
                type="date"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Pilih Lokasi MU"
              label-for="input-lokasi"
            >
              <registered-m-u-lists
                v-model="formData.location"
                @input="handleInputMU"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </section>
    </template>
    <template #content>
      <b-row class="d-flex justify-content-center">
        <b-col cols="auto">
          <div class="table-wrapper w-100 mx-auto overflow-scroll">
            <barcode-checker
              v-model="barcodesCollected"
              :api-service="apiService"
              @barcode-added="handleBarcodeAdded"
            />
          </div>
        </b-col>
      </b-row>

      <div class="d-flex justify-content-end">
        <b-button
          variant="primary"
          class="mt-3 mb-3"
          :disabled=" !isFormValid || isLoading"
          @click="postCollectedBloods"
        >
          <b-spinner
            v-if="isLoading"
            small
          />
          Simpan
        </b-button>
      </div>
    </template>
  </BaseLayout>
</template>

<script>
import {
  BButton, BCol, BFormGroup, BFormInput, BFormSelect, BFormSelectOption, BRow, BSpinner, BTable,
} from 'bootstrap-vue'
import manageBloodShipmentAPI from '../../../../../api/blood_shipment/manageBloodShipmentAPI'
import RegisteredMULists from '../../../../../components/shared/RegisteredMULists.vue'
import mapBloodType from '../../../../../utils/mapBloodType'
import BarcodeChecker from '../../../../../components/shared/BarcodeChecker.vue'

export default {
  name: 'AddBloodShipments',
  components: {
    BSpinner,
    BFormSelect,
    BFormInput,
    BButton,
    BCol,
    BRow,
    BFormGroup,
    BFormSelectOption,
    RegisteredMULists,
    BarcodeChecker,
  },
  data() {
    return {
      // state untuk button
      isLoading: false,
      // state untuk table
      isBusy: false,
      // formData untuk binding ke form
      formData: {
        barcode: '',
        division: null,
        location: '',
        date: '',
      },
      // untuk menampung data barcodes
      barcodesCollected: [],
      // untuk data item opsi dan tabel
      options: [
        { value: 'PENGOLAHAN', text: 'Pengolahan' },
        { value: 'KGD', text: 'KGD' },
        { value: 'IMLTD', text: 'IMLTD' },
      ],
      fields: [
        {
          key: 'no',
          label: 'No',
          sortable: true,
        },
        {
          key: 'barcode',
          label: 'Barcode',
          sortable: false,
        },
        {
          key: 'blood_bag_number',
          label: 'Nomor Selang',
          sortable: true,
        },
        {
          key: 'blood_type',
          label: 'Golongan Darah',
        },
        {
          key: 'bag_type',
          label: 'Jenis Kantong',
        },
      ],
      items: [],
      counter: 1,
    }
  },
  computed: {
    isFormValid() {
      return (
        this.formData.date
              && this.formData.division
              && this.formData.location
      )
    },
  },
  methods: {
    apiService(payload) {
      return manageBloodShipmentAPI.checkingBarcode(payload)
    },
    handleBarcodeAdded(newBarcode) {
      this.barcodesCollected = newBarcode
    },
    async postCollectedBloods() {
      this.isLoading = true

      try {
        const uniqueBarcodes = [...new Set(this.barcodesCollected)]

        const payload = {
          date: this.formData.date,
          mu_name: this.formData.location,
          barcodes: uniqueBarcodes,
          division: this.formData.division,
        }

        const response = await manageBloodShipmentAPI.addBloodShipment(payload)

        this.$bvToast.toast(response.data.message, {
          title: 'Data Berhasil Dikirim',
          variant: 'success',
          solid: true,
        })
      } catch (error) {
        this.$bvToast.toast(error.response?.data?.message || 'Terjadi kesalahan', {
          title: 'Data Gagal Dikirim',
          variant: 'danger',
          solid: true,
        })
      } finally {
        this.isLoading = false
      }
    },
    handleInputMU(selectedValue) {
      this.formData.location = selectedValue.name
    },
  },
}
</script>

<style scoped>
  .table-wrapper {
    max-height: 600px;
  }

  .overflow-scroll {
    overflow-y: auto;
  }
</style>
